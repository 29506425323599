import 'core-js/stable';

export default ({ configuration, ...other }: $TSFixMe) => {
    const filePath = __LOCALHOST__ && !__SERVE_FOR_BACKEND__ ? '/' : `${configuration.assetBaseUrl}/${__VERSION__}/`;
    const fileName = `navigatorApp${__LOCALHOST__ && !__SERVE_FOR_BACKEND__ ? '-devel' : ''}.js`;

    const script = window.document.createElement('script');
    script.src = `${filePath}${fileName}`;
    script.onload = function onload() {
        window.Mews.runNavigatorApp({ configuration, ...other });
    };
    window.document.head.appendChild(script);
};
